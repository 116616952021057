<section class="media">
    <ion-label>
        <ion-note *ngIf="required">*</ion-note>
        {{ label }}
    </ion-label>
    <div>
        <input #inputFile hidden="true" type="file" />
        <ion-button [disabled]="isDisabled" (click)="addFiles()" color="primary" expand="block" fill="solid" shape="round">
            {{ text }}
        </ion-button>
    </div>
</section>
