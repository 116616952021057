import { WeekDay } from '@angular/common';
import {
    addDays,
    addWeeks,
    endOfDay,
    format,
    getDate,
    getDay,
    getMonth,
    getYear,
    isAfter,
    isBefore,
    isSameDay,
    parse,
    set,
    setDay,
    startOfDay,
    startOfWeek,
} from 'date-fns';
import { first, isArray, last, isDate } from 'lodash-es';

import { PARSE_DATE_TIME_FORMAT } from '../constants/date-time-format.constants';
import { DailyShiftDTOInterface } from '../dto/daily-shift.dto.interface';
import { DiaryDTOInterface } from '../dto/dairy.dto.interface';
import { JobDataDTOInterface } from '../dto/job-data.dto.interface';
import { JobDTOInterface } from '../dto/job.dto.interface';
import { PlacementDTOInterface } from '../dto/placement.dto.interface';
import { ShiftDTOInterface } from '../dto/shift.dto.interface';
import { DailyShiftInterface } from '../interfaces/daily-shift.interface';
import { DateTimeNullablePairInterface, DateTimePairInterface } from '../interfaces/date-time-pair.interface';
import { DiaryInterface } from '../interfaces/diary.interface';
import { JobDataInterface } from '../interfaces/job-data.interface';
import { JobInterface } from '../interfaces/job.interface';
import { PlacementInterface } from '../interfaces/placement.interface';
import { ShiftInterface } from '../interfaces/shift.interface';

function parseDate(dateString: string, parseFormat: string = PARSE_DATE_TIME_FORMAT): Date {
    return parse(dateString, parseFormat, new Date());
}

export function diaryItemFromDto(dairyItemDto: DiaryDTOInterface): DiaryInterface {
    return {
        ...dairyItemDto,
        shiftStartDateTime: parseDate(dairyItemDto.shiftStartDateTime),
        shiftEndDateTime: parseDate(dairyItemDto.shiftEndDateTime),
    };
}

function diaryItemToDto(dairyItem: DiaryInterface): DiaryDTOInterface {
    return {
        ...dairyItem,
        shiftStartDateTime: isDate(dairyItem.shiftStartDateTime) ? format(dairyItem.shiftStartDateTime, PARSE_DATE_TIME_FORMAT) : '',
        shiftEndDateTime: isDate(dairyItem.shiftEndDateTime) ? format(dairyItem.shiftEndDateTime, PARSE_DATE_TIME_FORMAT) : '',
    };
}

export function dailyShiftsFromDto(dailyShiftDto: DailyShiftDTOInterface): DailyShiftInterface {
    return {
        ...dailyShiftDto,
        dayNumber: getDay(parseDate(dailyShiftDto.day, 'iiii')),
        shiftStartTime: set(parseDate(dailyShiftDto.shiftStartTime), {
            year: 1970,
            month: 0,
            date: 1,
        }),
        shiftEndTime: set(parseDate(dailyShiftDto.shiftEndTime), {
            year: 1970,
            month: 0,
            date: 1,
        }),
    };
}

function dailyShiftsToDto(dailyShift: DailyShiftInterface): DailyShiftDTOInterface {
    return {
        ...dailyShift,
        shiftStartTime: isDate(dailyShift.shiftStartTime) ? format(dailyShift.shiftStartTime, PARSE_DATE_TIME_FORMAT) : '',
        shiftEndTime: isDate(dailyShift.shiftEndTime) ? format(dailyShift.shiftEndTime, PARSE_DATE_TIME_FORMAT) : '',
    };
}

function datesComparer(a: Date, b: Date, sortDirection: 'ASC' | 'DESC' = 'ASC'): number {
    const sortDirectionMulti = sortDirection === 'ASC' ? 1 : -1;

    return (a.getTime() - b.getTime()) * sortDirectionMulti;
}

// HELPERS

function copyDate(toDate: Date, fromDate: Date): Date {
    return toDate = set(toDate, {
        date: getDate(fromDate),
        month: getMonth(fromDate),
        year: getYear(fromDate),
    });
}

function getToday(): Date {
    const now = Date.now();

    return startOfDay(now);
}

function getDateTimePairFromDiary(dairy: DiaryInterface | undefined): DateTimeNullablePairInterface | undefined {
    return dairy && {
        startDateTime: dairy.shiftStartDateTime,
        endDateTime: dairy.shiftEndDateTime,
    };
}

/**
 * Lead DailyShift to Date
 *
 * @export
 * @param {Date} date 'Destination date'
 * @param {DailyShiftInterface} dailyShift 'Selected Daily shift'
 * @returns {DateTimePairInterface} '{startDateTime: Date, endDateTime: Date}'
 */
export function getDateTimePairFromDailyShift(date: Date, dailyShift: DailyShiftInterface): DateTimePairInterface {
    const startDateTime = copyDate(dailyShift.shiftStartTime, date);
    const endDateTime = copyDate(dailyShift.shiftEndTime, date);

    if (isAfter(startDateTime, endDateTime)) {
        return {
            startDateTime,
            endDateTime: addDays(endDateTime, 1),
        };
    }

    return {
        startDateTime,
        endDateTime,
    };
}

function lastDailyShift(
    endDateTime: Date,
    dailyShifts: Array<DailyShiftInterface>,
): DateTimePairInterface | undefined {
    const endDayIndex = getDay(endDateTime);
    let dayIndex = endDayIndex === WeekDay.Sunday ? 7 : endDayIndex;
    let lastShiftOnEndWeek = lastDailyShiftByDay(dayIndex, dailyShifts);

    if (!lastShiftOnEndWeek) {
        dayIndex += 7;

        lastShiftOnEndWeek = lastDailyShiftByDay(dayIndex, dailyShifts);
    }

    if (!lastShiftOnEndWeek) {
        return undefined;
    }

    const lastWeekShiftDay = setDay(endDateTime, lastShiftOnEndWeek.dayNumber, { weekStartsOn: WeekDay.Monday });

    return getDateTimePairFromDailyShift(lastWeekShiftDay, lastShiftOnEndWeek);
}

function lastDailyShiftByDay(
    endDateIndex: number,
    dailyShifts: Array<DailyShiftInterface>,
): DailyShiftInterface | undefined {
    return last(dailyShifts
        .sort((prev, next) => {
            const normalPrev = prev.dayNumber === WeekDay.Sunday ? 7 : prev.dayNumber;
            const normalNext = next.dayNumber === WeekDay.Sunday ? 7 : next.dayNumber;

            return normalPrev - normalNext;
        })
        .filter(dailyShift => {
            const normalShiftDayNumber = dailyShift.dayNumber === WeekDay.Sunday ? 7 : dailyShift.dayNumber;

            return normalShiftDayNumber <= endDateIndex;
        }));
}

function firstDailyShift(
    startDateTime: Date,
    endDateTime: Date,
    dailyShifts: Array<DailyShiftInterface>,
): DateTimePairInterface | undefined {
    const startDayIndex = getDay(startDateTime);
    const shiftOnCurrentWeek = first(dailyShifts
        .filter(dailyShift => (dailyShift.dayNumber === 0 ? 7 : dailyShift.dayNumber) >= (startDayIndex === 0 ? 7 : startDayIndex)));

    if (!shiftOnCurrentWeek) {
        const nextWeek = startOfWeek(addWeeks(startDateTime, 1), { weekStartsOn: WeekDay.Monday });
        const shiftOnNextWeek = first(dailyShifts
            .filter(dailyShift => (dailyShift.dayNumber === 0 ? 7 : dailyShift.dayNumber) >= 1));

        if (!shiftOnNextWeek) {
            return undefined;
        }

        const nextWeekShiftDay = setDay(nextWeek, shiftOnNextWeek.dayNumber, { weekStartsOn: WeekDay.Monday });

        if (isAfter(nextWeekShiftDay, endDateTime)) {
            return undefined;
        }

        return getDateTimePairFromDailyShift(nextWeekShiftDay, shiftOnNextWeek);
    }

    const currentWeekShiftDay = setDay(startDateTime, shiftOnCurrentWeek.dayNumber, { weekStartsOn: WeekDay.Monday });

    if (isAfter(currentWeekShiftDay, endDateTime)) {
        return undefined;
    }

    return getDateTimePairFromDailyShift(currentWeekShiftDay, shiftOnCurrentWeek);
}

function nextDiary(diary: Array<DiaryInterface>): DiaryInterface | undefined {
    return diary.find(diaryItem => isAfter(diaryItem.shiftStartDateTime, getToday()));
}

// MAP

export function diaryAndShiftsDetailsFromDto(
    dto: { diary?: Array<DiaryDTOInterface>, dailyShifts?: Array<DailyShiftDTOInterface> },
): { diary?: Array<DiaryInterface>, dailyShifts?: Array<DailyShiftInterface> } {
    const diary = isArray(dto.diary) && dto.diary.length
        ? dto.diary
            .map(diaryItemFromDto)
            .sort((a, b) => datesComparer(a.shiftStartDateTime, b.shiftStartDateTime))
        // case when dairy sent as daily shifts O_o
        : isArray(dto.dailyShifts) &&
            dto.dailyShifts.length &&
            (first(dto.dailyShifts) as any).shiftStartDateTime
            ? ((dto.dailyShifts as any) as Array<DiaryDTOInterface>)
                .map(diaryItemFromDto)
                .sort((a, b) => datesComparer(a.shiftStartDateTime, b.shiftStartDateTime))
            : undefined;

    const dailyShifts = !diary && isArray(dto.dailyShifts) && dto.dailyShifts.length
        ? dto.dailyShifts
            .map(dailyShiftsFromDto)
            .sort((a, b) => (a.dayNumber === 0 ? 7 : a.dayNumber) - (b.dayNumber === 0 ? 7 : b.dayNumber))
        : undefined;

    return {
        diary,
        dailyShifts,
    };
}

function diaryAndShiftsDetailsToDto(
    model: { diary?: Array<DiaryInterface>, dailyShifts?: Array<DailyShiftInterface> },
): { diary?: Array<DiaryDTOInterface>, dailyShifts?: Array<DailyShiftDTOInterface> } {

    return {
        diary: isArray(model.diary) && model.diary.length ? model.diary.map(diaryItemToDto) : undefined,
        dailyShifts: isArray(model.dailyShifts) && model.dailyShifts.length ? model.dailyShifts.map(dailyShiftsToDto) : undefined,
    };
}

function shiftsFromDto(startDateTime: Date, endDateTime: Date, diary?: Array<DiaryInterface>, dailyShifts?: Array<DailyShiftInterface>): {
    firstShiftDateTimePair?: DateTimeNullablePairInterface;
    lastShiftDateTimePair?: DateTimeNullablePairInterface;
    nextShiftDateTimePair?: DateTimeNullablePairInterface;
} {
    const today = getToday();
    const jobStartsInFuture = isAfter(startDateTime, today) || isSameDay(startDateTime, today);
    const jobEndsInPast = isBefore(endDateTime, today);

    if (diary) {
        return {
            firstShiftDateTimePair: getDateTimePairFromDiary(first(diary)),
            lastShiftDateTimePair: getDateTimePairFromDiary(last(diary)),
            nextShiftDateTimePair: getDateTimePairFromDiary(nextDiary(diary)),
        };
    } else if (dailyShifts && startDateTime && endDateTime) {
        return {
            firstShiftDateTimePair: firstDailyShift(startDateTime, endDateTime, dailyShifts),
            lastShiftDateTimePair: lastDailyShift(endDateTime, dailyShifts),
            nextShiftDateTimePair: jobEndsInPast
                ? undefined
                : firstDailyShift(jobStartsInFuture ? startDateTime : today, endDateTime, dailyShifts),
        };
    }

    const oneShift = {
        startDateTime,
        endDateTime,
    };

    return {
        firstShiftDateTimePair: oneShift,
        lastShiftDateTimePair: oneShift,
        nextShiftDateTimePair: jobStartsInFuture ? oneShift : undefined,
    };

}

export function jobDataFromDto(jobDataDto: JobDataDTOInterface): JobDataInterface {
    const startDate = startOfDay(parseDate(jobDataDto.startDate));
    const endDate = endOfDay(parseDate(jobDataDto.endDate));
    const { diary, dailyShifts } = diaryAndShiftsDetailsFromDto(jobDataDto);

    const jobData: JobDataInterface = {
        ...jobDataDto,
        diary,
        dailyShifts,
        startDate,
        endDate,
        ...shiftsFromDto(startDate, endDate, diary, dailyShifts),
    } as JobDataInterface;

    return jobData;
}

function jobDataToDto(jobData: JobDataInterface): JobDataDTOInterface {
    const startDate = isDate(jobData?.startDate) ? format(jobData?.startDate, PARSE_DATE_TIME_FORMAT) : '';
    const endDate = isDate(jobData?.endDate) ? format(jobData?.endDate, PARSE_DATE_TIME_FORMAT) : '';
    const { diary, dailyShifts } = diaryAndShiftsDetailsToDto(jobData);

    const jobDataDto: JobDataDTOInterface = {
        ...jobData,
        diary,
        dailyShifts,
        startDate,
        endDate,
    } as JobDataDTOInterface;

    return jobDataDto;
}

function placementsFromDto(placementDto: PlacementDTOInterface): PlacementInterface {
    const placementStartDate = startOfDay(parseDate(placementDto.placementStartDate));
    const placementEndDate = endOfDay(parseDate(placementDto.placementEndDate));
    const { diary, dailyShifts } = diaryAndShiftsDetailsFromDto(placementDto);
    const placement: PlacementInterface = {
        ...placementDto,
        diary,
        dailyShifts,
        placementStartDate,
        placementEndDate,
        ...shiftsFromDto(placementStartDate, placementEndDate, diary, dailyShifts),
    } as PlacementInterface;

    return placement;
}

function placementsToDto(placement: PlacementInterface): PlacementDTOInterface {
    const placementStartDate = isDate(placement.placementStartDate) ? format(placement.placementStartDate, PARSE_DATE_TIME_FORMAT) : '';
    const placementEndDate = isDate(placement.placementEndDate) ? format(placement.placementEndDate, PARSE_DATE_TIME_FORMAT) : '';
    const { diary, dailyShifts } = diaryAndShiftsDetailsToDto(placement);

    const placementDto: PlacementDTOInterface = {
        ...placement,
        diary,
        dailyShifts,
        placementStartDate,
        placementEndDate,
    } as PlacementDTOInterface;

    return placementDto;
}

export function jobFromDto(jobDto: JobDTOInterface): JobInterface {
    return {
        ...jobDto,
        jobData: jobDataFromDto(jobDto.jobData) as JobDataInterface,
    };
}

export function jobToDto(job: JobInterface): JobDTOInterface {
    return {
        ...job,
        jobData: jobDataToDto(job.jobData) as JobDataDTOInterface,
    };
}

export function shiftFromDto(shiftDto: ShiftDTOInterface): ShiftInterface {
    return {
        ...shiftDto,
        jobData: jobDataFromDto(shiftDto.jobData) as JobDataInterface,
    };
}

export function shiftToDto(shift: ShiftInterface): ShiftDTOInterface {
    return {
        ...shift,
        jobData: jobDataToDto(shift.jobData) as JobDataDTOInterface,
    };
}

export function placementFromDto(placementDto: PlacementDTOInterface): PlacementInterface {
    return placementsFromDto(placementDto) as PlacementInterface;
}

export function placementToDto(placement: PlacementInterface): PlacementDTOInterface {
    return placementsToDto(placement) as PlacementDTOInterface;
}
