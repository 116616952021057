import { StoreWrapperInterface, STORE_WRAPPER_TOKEN } from '@actassa/api';
import { Component, ChangeDetectionStrategy, Input, Inject, OnInit } from '@angular/core';
import { isArray } from 'lodash-es';
import { Observable } from 'rxjs';
import { ChangelogInterface } from '../../interfaces/changelog.interface';

@Component({
    selector: 'jobs-placements-changelog',
    templateUrl: './changelog.component.html',
    styleUrls: ['./changelog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChangelogComponent implements OnInit {
    @Input() public items: Array<ChangelogInterface>;

    public timezone$: Observable<string>;

    constructor(
        @Inject(STORE_WRAPPER_TOKEN) private storeWrapper: StoreWrapperInterface,
    ) { }

    public ngOnInit(): void {
        this.timezone$ = this.storeWrapper.timezone$;
    }

    public get hasChangelog(): boolean {
        return isArray(this.items) && this.items.length > 0;
    }
}
