<ion-item *ngIf="item" [ngClass]="{ 'ion-margin-top': !!index }" (click)="select(item)" button detail
    detailIcon="chevron-forward-outline" lines="none">
    <ion-label class="ion-margin-vertical">
        <jobs-placements-list-item-status [item]="item"></jobs-placements-list-item-status>
        <ion-text>
            <h2 [ngClass]="{ bold: isHighlightedPlacement(item) }">
                {{ item.placementDescription }}
            </h2>
            <h3 *ngIf="item.client" [ngClass]="{ bold: isHighlightedPlacement(item) }">
                {{ item.client }}
            </h3>
        </ion-text>
        <ion-text class="placement-time" color="primary">
            <h3 [innerHtml]="getFirstFutureShift(item)"></h3>
        </ion-text>
    </ion-label>
</ion-item>