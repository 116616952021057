import { RootStateInterface } from '../interfaces/root-state.interface';

export const DEFAULT_ROOT_STATE_DATA: RootStateInterface = {
    app: null,
    auths: [],
    appSettings: null,
    awaitingDeviceToken: null,
    client: null,
    deviceID: null,
    deviceToken: null,
    deviceType: null,
    isAppStateActive: true,
    isDebugMode: false,
    isLoading: false,
    notificationData: null,
    providerID: '',
    redirectURL: null,
    timezone: 'UTC',
    token: null,
    user: {
        email: '',
        firstName: '',
        id: 0,
        lastName: '',
        uuid: '',
        consultantID: 0,
        consultantEmail: '',
    },
};
