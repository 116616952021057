<ng-container *ngIf="hasDiary">
    <section class="ion-paddig button-top">
        <ion-button fill="clear" size="small">{{ diary.length }} shifts</ion-button>
        <ion-button (click)="selectAll()" color="primary" fill="outline" size="small"> Select All </ion-button>
        <ion-button (click)="clearAll()" color="primary" fill="outline" size="small"> Clear All </ion-button>
        <ion-item *ngIf="note" lines="none">
            <ion-label (click)="showNote()" class="ion-text-nowrap notes-label">{{ note }}</ion-label>
        </ion-item>
    </section>
    <div *ngIf="note" style="height: 48px"></div>
    <ion-list *ngIf="!disabled" class="ion-no-margin ion-no-padding list-wrapper">
        <ion-item *ngFor="let shift of diary" (click)="handleShiftStatusChanged(shift)"
            class="ion-no-margin ion-no-padding shift-item" lines="none">
            <ion-checkbox [checked]="isShiftChecked(shift)" mode="md" slot="start"> </ion-checkbox>
            <ion-label class="ion-text-wrap">
                <h3>{{ getShiftDescription(shift) }}</h3>
                <h3>{{ shift.jobTitle }}</h3>
                <h3>{{ shift.client }}; {{ shift.workAddress }}</h3>
            </ion-label>
        </ion-item>
    </ion-list>
</ng-container>