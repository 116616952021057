import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';

import { SelectClientRoutingModule } from './select-client-routing.module';
import { SelectClientComponent } from './select-client.component';

@NgModule({
    declarations: [
        SelectClientComponent,
    ],
    imports: [
        CommonModule,
        SelectClientRoutingModule,
        IonicModule,
        FormsModule,
        ReactiveFormsModule,
    ],
    exports: [
        SelectClientComponent,
    ],
})
export class SelectClientComponentModule { }
