import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
    selector: 'shared-image-preview',
    templateUrl: './image-preview.component.html',
    styleUrls: ['./image-preview.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImagePreviewComponent {
    @Input() public url!: string;

    public style = { transform: '', msTransform: '', oTransform: '', webkitTransform: '' };

    private scale = 1;
    private rotation = 0;
    private translateX = 0;
    private translateY = 0;
    private zoomFactor = 0.2;
    private readonly maxZoomScale = 4;
    private readonly minZoomScale = 1;

    public zoomIn(): void {
        if (this.scale < this.maxZoomScale) {
            this.scale *= (1 + this.zoomFactor);
            this.translateX = (this.scale - 1) * 200 - 10;
            this.translateY = this.translateX;
            this.updateStyle();
        }
    }

    public zoomOut(): void {
        if (this.scale > this.minZoomScale) {
            this.scale /= (1 + this.zoomFactor);
            this.translateX = (this.scale - 1) * 200 - 10;
            this.translateY = this.translateX;
            this.updateStyle();
        }
    }

    public reset(): void {
        this.scale = 1;
        this.translateX = 0;
        this.translateY = 0;
        this.rotation = 0;
        this.updateStyle();
    }

    public rotateClockwise(): void {
        this.rotation += 90;
        this.updateStyle();
    }

    private updateStyle(): void {
        this.style.transform = `translate(${this.translateX}px, ${this.translateY}px) rotate(${this.rotation}deg) scale(${this.scale})`;
        this.style.msTransform = this.style.transform;
        this.style.webkitTransform = this.style.transform;
        this.style.oTransform = this.style.transform;
    }
}
