import { BehaviorSubject, Observable } from 'rxjs';

export abstract class AbstractLoading {
    public loading$!: Observable<boolean>;

    private _loading$ = new BehaviorSubject<boolean>(false);

    constructor() {
        this.loading$ = this._loading$.asObservable();
    }

    protected startLoading(): void {
        this._loading$.next(true);
    }

    protected stopLoading(): void {
        this._loading$.next(false);
    }
}
