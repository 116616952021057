import { ApplicationsEnum, Environment, ENVIRONMENT_TOKEN, StoreWrapperInterface, STORE_WRAPPER_TOKEN } from '@actassa/api';
import { AppPathsDictionary } from '@actassa/api';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { Navigate } from '@ngxs/router-plugin';
import { Actions, ofActionDispatched } from '@ngxs/store';
import { isEqual } from 'lodash-es';
import { Socket } from 'ngx-socket-io';
import { BehaviorSubject, combineLatest, merge, Observable } from 'rxjs';
import { debounceTime, delay, distinctUntilChanged, filter, map, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { SelectRole } from 'state/root-state/actions/select-role';
import { SetUserOffline } from 'state/root-state/actions/set-user-offline';
import { SetUserOnline } from 'state/root-state/actions/set-user-online';
import { AddMessage } from '../modules/messaging/+state/actions/add-message';
import { SetMessages } from '../modules/messaging/+state/actions/set-messages';
import { SetRoom } from '../modules/messaging/+state/actions/set-room';
import { SetRooms } from '../modules/messaging/+state/actions/set-rooms';
import { SetUserChange } from '../modules/messaging/+state/actions/set-user-change';
import { UpdateMessage } from '../modules/messaging/+state/actions/update-message';
import { UserOffline } from '../modules/messaging/+state/actions/user-offline';
import { UserOnline } from '../modules/messaging/+state/actions/user-online';
import { Message } from '../modules/messaging/models/message';
import { Room } from '../modules/messaging/models/room';
import { UserChangeEvent } from '../modules/messaging/models/user-change.event';

@Injectable({
    providedIn: 'root',
})
export class ApplicationService {
    private socketActivator$ = new BehaviorSubject<SetUserOffline | SetUserOnline | null>(null);

    constructor(
        @Inject(STORE_WRAPPER_TOKEN) private storeWrapper: StoreWrapperInterface,
        @Inject(ENVIRONMENT_TOKEN) private readonly environment: Environment,
        private readonly actions$: Actions,
        private readonly http: HttpClient,
        private readonly socket: Socket,
    ) { }

    public init(): Observable<unknown> {
        return merge(
            this.socket.fromEvent<Array<Room>>('rooms')
                .pipe(
                    tap((rooms) => this.setRooms(rooms)),
                ),
            this.socket.fromEvent<Room>('room')
                .pipe(
                    tap(room => this.setRoom(room)),
                ),
            this.socket.fromEvent<Array<Message>>('messages')
                .pipe(
                    tap(messages => this.setMessages(messages)),
                ),
            this.socket.fromEvent<Message>('message')
                .pipe(
                    tap(message => this.addMessage(message)),
                ),
            this.socket.fromEvent<Message>('update-message')
                .pipe(
                    tap(message => this.updateMessage(message)),
                ),
            this.socket.fromEvent<{ uuid: string }>('user-online')
                .pipe(
                    withLatestFrom(this.storeWrapper.user$),
                )
                .pipe(
                    tap(([{ uuid: id }, user]) => id !== user.uuid ? this.userOnline(id) : void (0)),
                ),
            this.socket.fromEvent<{ uuid: string }>('user-offline')
                .pipe(
                    withLatestFrom(this.storeWrapper.user$),
                    tap(([{ uuid: id }, user]) => id !== user.uuid ? this.userOffline(id) : void (0)),
                ),
            this.socket.fromEvent<UserChangeEvent>('users-changed')
                .pipe(
                    tap(event => this.setUserChange(event)),
                ),
            this.actions$
                .pipe(
                    ofActionDispatched(SelectRole),
                    map(({ config }: SelectRole) => config.role),
                    filter((app: ApplicationsEnum) => Boolean(app)),
                    delay(0),
                    switchMap((app: ApplicationsEnum) => this.setServerActiveApplication$(app)),
                ),
            this.actions$
                .pipe(
                    ofActionDispatched(SetUserOnline),
                    filter(({ uuid }: SetUserOnline) => Boolean(uuid)),
                    tap((action: SetUserOnline) => this.socketActivator$.next(action)),
                ),
            this.actions$
                .pipe(
                    ofActionDispatched(SetUserOffline),
                    filter((value: SetUserOffline) => Boolean(value?.uuid)),
                    tap((action: SetUserOffline) => this.socketActivator$.next(action)),
                ),
            combineLatest([
                this.socketActivator$.asObservable(),
                this.storeWrapper.getMenuItemProperty$('MESSAGING', 'isActive'),
            ])
                .pipe(
                    distinctUntilChanged(isEqual),
                    debounceTime(1000),
                    tap(([value, isActive]: [any, boolean]) => {
                        if (isActive && value?.app) {
                            return this.setUserOnline(value);
                        }

                        this.setUserOffline(value);
                    }),
                ),
        );
    }

    private setUserOnline({ uuid, name, app }: SetUserOnline): void {
        this.socket.emit('set-online', { uuid, name, app });

        this.socket.on('connect', () => {
            this.socket.emit('set-online', { uuid, name, app });
        });
    }

    private setUserOffline(value: SetUserOffline): void {
        this.socket.emit('set-offline', value?.uuid);
    }

    private setServerActiveApplication$(application: ApplicationsEnum): Observable<any> {
        this.gotoAppPage(application);

        return this.http.post(`${this.environment.apiURL}/v2/auth/set-active-app`, { application })
            .pipe(take(1));
    }

    @Dispatch()
    public gotoAppPage(app: ApplicationsEnum): Navigate {
        return new Navigate(['/', AppPathsDictionary[app]]);
    }

    @Dispatch()
    private setRooms(rooms: Array<Room>): SetRooms {
        return new SetRooms(rooms);
    }

    @Dispatch()
    private setRoom(room: Room): SetRoom {
        return new SetRoom(room);
    }

    @Dispatch()
    private setMessages(messages: Array<Message>): SetMessages {
        return new SetMessages(messages);
    }

    @Dispatch()
    private addMessage(message: Message): AddMessage {
        return new AddMessage(message);
    }

    @Dispatch()
    private updateMessage(message: Message): UpdateMessage {
        return new UpdateMessage(message);
    }

    @Dispatch()
    private userOnline(uuid: string): UserOnline {
        return new UserOnline(uuid);
    }

    @Dispatch()
    private userOffline(uuid: string): UserOffline {
        return new UserOffline(uuid);
    }

    @Dispatch()
    private setUserChange(userChangeEvent: UserChangeEvent): SetUserChange {
        return new SetUserChange(userChangeEvent);
    }
}
