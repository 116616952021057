import { Environment, ENVIRONMENT_TOKEN, PlatformEnum } from '@actassa/api';
import { Inject, Injectable } from '@angular/core';
import { DeviceInfo, Plugins } from '@capacitor/core';
import { defer, Observable } from 'rxjs';

const { Device } = Plugins;

@Injectable({
    providedIn: 'root',
})
export class AppVersionService {
    constructor(
        @Inject(ENVIRONMENT_TOKEN) private readonly environment: Environment,
    ) { }

    public getAppVersion$(): Observable<string> {
        return defer(() => Device.getInfo()
            .then((info: DeviceInfo) => {
                if (info.platform !== PlatformEnum.WEB) {
                    return info.appVersion;
                }

                return this.environment.appVersion;
            }));
    }
}
