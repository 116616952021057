import { Environment, ENVIRONMENT_TOKEN, MobileApplicationConfig } from '@actassa/api';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { AppVersionService } from './app-version.service';

interface ResponseDTO {
    status: string,
    message: string,
    data: Partial<MobileApplicationConfig>,
};

@Injectable()
export class AppSettingsDataService {
    constructor(
        @Inject(ENVIRONMENT_TOKEN) private readonly environment: Environment,
        private readonly http: HttpClient,
        private readonly appVersionService: AppVersionService,
    ) { }

    public getSettings$(): Observable<Partial<MobileApplicationConfig>> {
        return this.appVersionService.getAppVersion$()
            .pipe(
                switchMap((version => this.http.get<ResponseDTO>(`${this.environment.apiURL}/settings/${version}`))),
                map(({ data }) => data),
            );
    }
}
