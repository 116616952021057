<ng-container *ngIf="hasDiary">
    <section *ngIf="disabled" class="ion-margin-top">
        <div>
            <ion-text color="primary">Job calendar:</ion-text>
        </div>
        <ul>
            <li *ngFor="let shift of diary">
                <ion-text color="dark">
                    {{ getShiftDescription(shift) }}
                </ion-text>
            </li>
        </ul>
    </section>

    <ion-list *ngIf="!disabled" class="ion-no-margin ion-no-padding ion-margin-top">
        <ion-item lines="none" class="ion-no-margin ion-no-padding action-buttons">
            <ion-button (click)="selectAll()" color="primary" fill="outline" size="small" class="ion-no-margin">Select
                All </ion-button>
            <ion-button (click)="clearAll()" color="primary" fill="outline" size="small" class="ion-no-margin">Clear All
            </ion-button>
        </ion-item>
        <ion-item *ngFor="let shift of diary" class="ion-no-margin ion-no-padding" lines="none"
            (click)="handleShiftStatusChanged(shift)">
            <ion-checkbox slot="start" [checked]="isShiftChecked(shift)" mode="md"> </ion-checkbox>
            <ion-label>
                <h3>{{ getShiftDescription(shift) }}</h3>
            </ion-label>
        </ion-item>
    </ion-list>
</ng-container>