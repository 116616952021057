<div class="loader">
    <ng-container *ngIf="isLoading; then loadingStart; else loadingEnd"></ng-container>
</div>

<ng-template #loadingStart>
    <ion-progress-bar type="indeterminate"></ion-progress-bar>
</ng-template>

<ng-template #loadingEnd>
    <ion-progress-bar color="light"></ion-progress-bar>
</ng-template>