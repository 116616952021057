import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { SectionsEnum } from '../../enums/sections.enum';
import { CollectionInterface } from '../../interfaces/collection.interface';
import { JobInterface } from '../../interfaces/job.interface';
import { PlacementInterface } from '../../interfaces/placement.interface';
import { ShiftInterface } from '../../interfaces/shift.interface';

@Component({
    selector: 'jobs-placements-service-info',
    templateUrl: './service-info.component.html',
    styleUrls: ['./service-info.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ServiceInfoComponent implements OnInit {
    @Input() public type: SectionsEnum;
    @Input() public item: JobInterface | ShiftInterface | CollectionInterface | PlacementInterface;

    public data: string;

    private strategy = {
        [SectionsEnum.COLLECTION]: (item: CollectionInterface): string => `CID: ${item?.collectionData?.collectionId}`,
        [SectionsEnum.JOB]: (item: JobInterface): string => `JID: ${item?.jobData?.jobId}`,
        [SectionsEnum.PLACEMENT]: (item: PlacementInterface): string => `JID: ${item?.jobId} | PID ${item?.placementId}`,
        [SectionsEnum.SHIFT]: (item: ShiftInterface): string => `JID: ${item?.jobData?.jobId} | SID ${item?.jobData?.shiftId}`,
    };

    public ngOnInit(): void {
        this.data = this.buildData();
    }

    private buildData(): string {
        const handler = this.strategy[this.type];

        if (!handler) {
            return '';
        }

        return handler(this.item);
    }
}
