import { Component, ChangeDetectionStrategy, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { format } from 'date-fns';
import { isArray } from 'lodash-es';
import { JobStatus } from '../../enums/job-status.enum';
import { DiaryInterface } from '../../interfaces/diary.interface';
import { ShiftStatus } from '../../interfaces/shift-status.interface';

@Component({
    selector: 'jobs-placements-diary',
    templateUrl: './diary.component.html',
    styleUrls: ['./diary.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DiaryComponent),
            multi: true,
        },
    ],
})
export class DiaryComponent implements ControlValueAccessor {

    @Input() public diary: Array<DiaryInterface> | undefined;

    @Input() public disabled: boolean;

    private shiftsStatusesHash!: Record<number, JobStatus>;
    private onTouched!: () => void;
    private onChanged!: (newValue: Array<ShiftStatus>) => void;

    public get hasDiary(): boolean {
        return isArray(this.diary);
    }

    public writeValue(shiftsStatuses: Array<ShiftStatus>): void {
        this.shiftsStatusesHash = shiftsStatuses.reduce((shiftStatusesHash, { shiftId, status }) => {
            if (
                status !== JobStatus.DECLINED_OPPORTUNITY &&
                status !== JobStatus.CURRENT_APPLICATION
            ) {
                status = JobStatus.DECLINED_OPPORTUNITY;
            }

            shiftStatusesHash[shiftId] = status;
            return shiftStatusesHash;
        }, {} as Record<number, JobStatus>);
    }

    public registerOnChange(fn: () => void): void {
        this.onChanged = fn;
    }

    public registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    public setDisabledState(disabled: boolean): void {
        this.disabled = disabled;
    }

    public selectAll(): void {
        this.setStatusForAll(JobStatus.CURRENT_APPLICATION);
    }

    public clearAll(): void {
        this.setStatusForAll(JobStatus.DECLINED_OPPORTUNITY);
    }

    public getShiftDescription(shift: DiaryInterface): string {
        const { shiftStartDateTime, shiftEndDateTime } = shift;

        const startDay = format(shiftStartDateTime, 'iii d MMM');
        const startTime = format(shiftStartDateTime, 'h:mm aaa');
        let endTime = format(shiftEndDateTime, 'h:mm aaa');

        if (startTime === endTime) {
            endTime = 'TBD';
        }

        return `${startDay}, ${startTime} - ${endTime}`;

    }

    public handleShiftStatusChanged(shift: DiaryInterface): void {
        this.shiftsStatusesHash = this.shiftsStatusesHash || {};

        if (shift.shiftId) {
            this.shiftsStatusesHash[shift.shiftId] = this.isShiftChecked(shift)
                ? JobStatus.DECLINED_OPPORTUNITY
                : JobStatus.CURRENT_APPLICATION;
        }

        this.updateValue();
    }

    public isShiftChecked(shift: DiaryInterface): boolean {
        return shift.shiftId
            ? this.shiftsStatusesHash?.[shift.shiftId] === JobStatus.CURRENT_APPLICATION
            : false;
    }

    private setStatusForAll(status: JobStatus): void {
        this.shiftsStatusesHash = (this.diary || []).reduce((shiftsStatusesHash, shift) => {
            if (shift.shiftId) {
                shiftsStatusesHash[shift.shiftId] = status;
            }

            return shiftsStatusesHash;
        }, {} as Record<number, JobStatus>);

        this.updateValue();
    }

    private updateValue(): void {
        const newShiftsStatuses: Array<ShiftStatus> =
            Object.entries(this.shiftsStatusesHash).map(([shiftIdString, status]) => ({
                shiftId: parseInt(shiftIdString, 10),
                status,
            }));

        if (this.onTouched) {
            this.onTouched();
        }

        if (this.onChanged) {
            this.onChanged(newShiftsStatuses);
        }
    }
}
