import { parseDateAsDateInTimezone } from '@actassa/shared';
import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { format } from 'date-fns';
import { ChangelogInterface } from '../../interfaces/changelog.interface';

@Component({
    selector: 'jobs-placements-changelog-item',
    templateUrl: './changelog-item.component.html',
    styleUrls: ['./changelog-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChangelogItemComponent {
    @Input() public item: ChangelogInterface;
    @Input() public timezone: string;

    public dateFormat(date: string): string {
        return format(parseDateAsDateInTimezone(date, this.timezone), 'd MMM yyy');
    }
}
