import { Component, ChangeDetectionStrategy, Input, OnInit } from '@angular/core';

@Component({
    selector: 'actassa-form-skeleton',
    templateUrl: './form-skeleton.component.html',
    styleUrls: ['./form-skeleton.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormSkeletonComponent implements OnInit {
    @Input() public count = 0;
    public inputs: Array<number> = [];

    public ngOnInit(): void {
        this.inputs = new Array(this.count);
    }
}
