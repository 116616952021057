// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import versionConfig from '../../../../package.json';

export const environment = {
    production: false,
    apiURL: 'https://entdev.actassa.com/mobile-dev',
    botURL: 'wss://entdev.actassa.com/dev/webchat001/',
    firebase: {
        apiKey: 'AIzaSyCcXkmmuQ9K3WT2xrra5xNq4Sv7wFE-iaw',
        authDomain: 'enterprise-nz.firebaseapp.com',
        projectId: 'enterprise-nz',
        storageBucket: 'enterprise-nz.appspot.com',
        messagingSenderId: '659669353683',
        appId: '1:659669353683:web:61c81d0ebdee5c6dc40026',
    },
    socketIoConfig: {
        url: 'https://entdev.actassa.com:3006',
        options: {
            autoConnect: true,
        },
    },
    appVersion: versionConfig.version,
    appId: 'com.actassa.enterprise',
    appleAppId: '1543990033',
    appName: 'Enterprise',
    platform: 'web',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
