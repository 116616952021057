import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RoutesDictionary } from 'dictionaries/routes.dictionary';
import { AuthGuard } from 'guards/auth.guard';

const routes: Routes = [
    {
        path: '',
        redirectTo: 'login',
        pathMatch: 'full',
    },
    {
        path: RoutesDictionary.LOGIN,
        loadChildren: (): Promise<unknown> => import('./pages/login/login.module').then(m => m.LoginPageModule),
    },
    {
        path: RoutesDictionary.LOGOUT,
        loadChildren: (): Promise<unknown> => import('./pages/logout/logout.module').then(m => m.LogoutPageModule),
    },
    {
        path: RoutesDictionary.SELECT_APP,
        canActivate: [AuthGuard],
        loadChildren: (): Promise<unknown> => import('./pages/select-app/select-app.module').then(m => m.SelectAppPageModule),
    },
    {
        path: RoutesDictionary.FORGOT_PASSWORD,
        loadChildren: (): Promise<unknown> => import('./pages/forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule),
    },
    {
        path: RoutesDictionary.NO_ACCOUNT,
        loadChildren: (): Promise<unknown> => import('./pages/no-account/no-account.module').then(m => m.NoAccountModule),
    },
    {
        path: 'shell',
        canActivate: [AuthGuard],
        loadChildren: (): Promise<unknown> => import('./apps/shell/shell.module').then(m => m.ShellModule),
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule { }
