import { Component, ChangeDetectionStrategy, Output, EventEmitter } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { ApplyFilterAction } from '../../+state/app-state/actions/apply-filter.action';
import { RemoveFilterAction } from '../../+state/app-state/actions/remove-filter.action';
import { JobsPlacementsState } from '../../+state/app-state/app.state';
import { FilterEventType } from '../../enums/filter-event-type.enum';
import { FilterEventInterface } from '../../interfaces/filter-event.interface';
import { SavedFilterInterface } from '../../interfaces/saved-filter.interface';

@Component({
    selector: 'jobs-placements-saved-filter-list',
    templateUrl: './saved-filter-list.component.html',
    styleUrls: ['./saved-filter-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SavedFilterListComponent {
    @Select(JobsPlacementsState.savedFilters$) public savedFilters$!: Observable<Array<SavedFilterInterface>>;

    @Output() public filterEvent = new EventEmitter<FilterEventInterface>();

    constructor(
        private readonly modalController: ModalController,
    ) { }

    public close(): void {
        this.modalController.dismiss();
    }

    public filterEventHandler(event: FilterEventInterface): void {
        const strategy = {
            [FilterEventType.APPLY]: (id: number): unknown => this.applyFilter(id),
            [FilterEventType.REMOVE]: (id: number): unknown => this.deleteFilter(id),
        };

        const handler = strategy[event.type];

        if (!handler) {
            return;
        }

        handler(event.id);
    }

    private applyFilter(id: number): void {
        this.applyFilterAction(id);
        this.close();
    }

    @Dispatch()
    private deleteFilter(id: number): RemoveFilterAction {
        return new RemoveFilterAction(id);
    }

    @Dispatch()
    private applyFilterAction(id: number | null): ApplyFilterAction {
        return new ApplyFilterAction(id);
    }
}
