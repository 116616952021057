import { CandidateSectionsEnum } from '../enums/candidate-sections.enum';

export const CandidateMenuTitlesDictionary: Record<CandidateSectionsEnum, string> = {
    [CandidateSectionsEnum.AVAILABILITY]: 'My Availability',
    [CandidateSectionsEnum.COMPLIANCE]: 'Compliance',
    [CandidateSectionsEnum.DATA_COLLECTION]: 'Data collection',
    [CandidateSectionsEnum.DOCUMENTS]: 'My Documents',
    [CandidateSectionsEnum.HELP]: 'Help',
    [CandidateSectionsEnum.HOME]: 'Home',
    [CandidateSectionsEnum.JOBS_PLACEMENTS]: 'Jobs & Placements',
    [CandidateSectionsEnum.LOGOUT]: 'Logout',
    [CandidateSectionsEnum.MESSAGING]: 'Messaging',
    [CandidateSectionsEnum.SELECT_APPLICATION]: 'Select application',
    [CandidateSectionsEnum.SETTINGS]: 'Account & Settings',
};
