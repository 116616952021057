import { Client, UserInterface } from '@actassa/api';
import { Component, ChangeDetectionStrategy, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { Select } from '@ngxs/store';
import { isEqual } from 'lodash-es';
import { Observable, of } from 'rxjs';
import { distinctUntilChanged, filter, map, startWith, switchMap, takeUntil, tap, withLatestFrom } from 'rxjs/operators';
import { SetClient } from 'state/root-state/actions/set-client';
import { RootState } from 'state/root-state/root.state';

import { DestroyService } from '../../services/destroy.service';

import { SelectClientFormKeys } from './select-client-form-keys.enum';
import { SelectClientService } from './select-client.service';

@Component({
    selector: 'shared-select-client',
    templateUrl: './select-client.component.html',
    styleUrls: ['./select-client.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [DestroyService],
})
export class SelectClientComponent implements OnInit {
    @Select(RootState.client$) private selectedClient$: Observable<Client>;
    @Select(RootState.user$) private user$: Observable<UserInterface>;

    @Input() public disabled = false;
    @Input() public linesMode: 'full' | 'insert' | 'none' = 'insert';

    @Output() public loading = new EventEmitter<boolean>();

    public clients$!: Observable<Array<Client>>;
    public form: FormGroup;
    public selectPopupOptions = {
        header: 'Select Client',
        subHeader: '',
        message: '',
        translucent: true,
    };
    public selectedClientText$: Observable<string>;
    public selectClientFormKeys = SelectClientFormKeys;
    public loading$: Observable<boolean>;

    constructor(
        private readonly onDestroy$: DestroyService,
        private readonly selectClientService: SelectClientService,
    ) { }

    public ngOnInit(): void {
        this.clients$ = this.getClients$();
        this.form = this.buildForm();
        this.formHandle(this.form);
        this.selectedClientText$ = this.buildSelectedClientText(this.form);
        this.loading$ = this.initLoading$();
    }

    private formHandle(form: FormGroup): void {
        const clientFormField = form.get(SelectClientFormKeys.CLIENT);

        if (!clientFormField) {
            return;
        }

        clientFormField.valueChanges
            .pipe(
                startWith(clientFormField.value),
                takeUntil(this.onDestroy$),
                distinctUntilChanged(isEqual),
                filter(Boolean),
            )
            .subscribe((client) => this.setClient(client));

        this.selectedClient$
            .pipe(
                takeUntil(this.onDestroy$),
                distinctUntilChanged(isEqual),
            )
            .subscribe((client) => clientFormField.patchValue(client));
    }

    private buildSelectedClientText(form: FormGroup): Observable<string> {
        const clientFormField = form.get(SelectClientFormKeys.CLIENT);

        if (!clientFormField) {
            return of();
        }

        return clientFormField.valueChanges
            .pipe(
                startWith(clientFormField.value),
                map((client: Client) => client?.name),
            );
    }

    public compareWith(o1: Client, o2: Client): boolean {
        return o1?.id === o2?.id;
    }

    private buildForm(): FormGroup {
        return new FormGroup({
            [SelectClientFormKeys.CLIENT]: new FormControl(null),
        });
    }

    private initLoading$(): Observable<boolean> {
        return this.selectClientService.loading$
            .pipe(
                tap((loadingStatus: boolean) => this.loading.emit(loadingStatus)),
            );
    }

    private getClients$(): Observable<Array<Client>> {
        return this.user$
            .pipe(
                switchMap(user => this.selectClientService.getClients$(user.id)),
                withLatestFrom(this.selectedClient$),
                tap(([clients, selectedClient]: [Array<Client>, Client]) => {
                    if (!selectedClient?.id) {
                        return;
                    }

                    const isSelectedClientInList = clients.find((client) => client.id === selectedClient?.id);

                    if (!isSelectedClientInList) {
                        this.setClient(null);
                    }
                }),
                map(([clients]: [Array<Client>, Client]) => clients),
            );
    }

    @Dispatch()
    private setClient(data: Client): SetClient {
        return new SetClient(data);
    }
}
