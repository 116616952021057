import { DetailListModule, PrettyJsonModule, SafeModule } from '@actassa/shared';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { JobListItemClientPipe } from '../pipes/job-list-item-client.pipe';
import { JobListItemSubTitlePipe } from '../pipes/job-list-item-sub-title.pipe';
import { JobListItemTitlePipe } from '../pipes/job-list-item-title.pipe';
import { PayRatePipe } from '../pipes/pay-rate.pipe';
import { ShiftTimeDescriptionPipe } from '../pipes/shift-time-description.pipe';
import { ChangelogItemComponent } from './changelog-item/changelog-item.component';
import { ChangelogComponent } from './changelog/changelog.component';
import { CollectionDiaryComponent } from './collection-diary/collection-diary.component';
import { DailyShiftsComponent } from './daily-shifts/daily-shifts.component';
import { DebugInfoComponent } from './debug-info/debug-info.component';
import { DiaryComponent } from './diary/diary.component';
import { FooterComponent } from './footer/footer.component';
import { JobDescriptionComponent } from './job-description/job-description.component';
import { JobNotesComponent } from './job-notes/job-notes.component';
import { ListItemJobComponent } from './list-item-job/list-item-job.component';
import { ListItemPlacementComponent } from './list-item-placement/list-item-placement.component';
import { ListItemStatusComponent } from './list-item-status/list-item-status.component';
import { ListItemTextComponent } from './list-item-text/list-item-text.component';
import { ListLoadingComponent } from './list-loading/list-loading.component';
import { ListOfPlacementsComponent } from './list-of-placements/list-of-placements.component';
import { SavedFilterListItemComponent } from './saved-filter-list-item/saved-filter-list-item.component';
import { SavedFilterListComponent } from './saved-filter-list/saved-filter-list.component';
import { ServiceInfoComponent } from './service-info/service-info.component';

const components = [
    ChangelogComponent,
    ChangelogItemComponent,
    CollectionDiaryComponent,
    DailyShiftsComponent,
    DebugInfoComponent,
    DiaryComponent,
    JobDescriptionComponent,
    JobListItemClientPipe,
    JobListItemSubTitlePipe,
    JobListItemTitlePipe,
    JobNotesComponent,
    JobNotesComponent,
    ListItemJobComponent,
    ListItemPlacementComponent,
    ListItemStatusComponent,
    ListItemTextComponent,
    ListLoadingComponent,
    ListOfPlacementsComponent,
    PayRatePipe,
    FooterComponent,
    SavedFilterListComponent,
    SavedFilterListItemComponent,
    ShiftTimeDescriptionPipe,
    ServiceInfoComponent,
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        PrettyJsonModule,
        DetailListModule,
        SafeModule,
    ],
    declarations: [...components],
    exports: [...components],
})
export class SharedComponentModule { }
