import { EMPTY_FUNCTION } from '@actassa/shared';
import { Component, ChangeDetectionStrategy, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
    selector: 'shared-complex-control-value',
    templateUrl: './complex-control-value.component.html',
    styleUrls: ['./complex-control-value.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ComplexControlValueComponent),
            multi: true,
        },
    ],
})
export class ComplexControlValueComponent<T> implements ControlValueAccessor, OnInit {
    @Input() public autoGrow = true;
    @Input() public maxlength = 100;
    @Input() public placeholder = '';
    @Input() public readonly = true;
    @Input() public rows = 1;

    public displayValue$!: Observable<string | null>;
    public onChange = EMPTY_FUNCTION;
    public onTouch = EMPTY_FUNCTION;

    private _value$ = new BehaviorSubject<T | null>(null);

    @Input() public displayFn: (value: T | null) => string = () => '';

    public set value(value: T | null) {
        const previousValue = this._value$;

        this._value$.next(value);
        this.onChange(value, previousValue);
        this.onTouch();
    }

    public get value(): T | null {
        return this._value$.getValue();
    }

    public ngOnInit(): void {
        this.displayValue$ = this._value$
            .pipe(
                map(value => this.displayFn(value)),
            );
    }

    public writeValue(value: T): void {
        this._value$.next(value);
    }

    public registerOnChange(fn: (value: T) => void): void {
        this.onChange = fn;
    }

    public registerOnTouched(fn: () => void): void {
        this.onTouch = fn;
    }
}
