<ng-container *ngIf="url">
    <div class="image-wrapper">
        <ion-img [ngStyle]="style" [src]="[url]"></ion-img>
    </div>
    <div class="control-wrapper">
        <ion-button (click)="zoomIn()" fill="clear" size="large" slot="icon-only">
            <ion-icon name="add-outline"></ion-icon>
        </ion-button>
        <ion-button (click)="zoomOut()" fill="clear" size="large" slot="icon-only">
            <ion-icon name="remove-outline"></ion-icon>
        </ion-button>
        <ion-button (click)="rotateClockwise()" fill="clear" size="large" slot="icon-only">
            <ion-icon name="sync-outline"></ion-icon>
        </ion-button>
        <ion-button (click)="reset()" fill="clear" size="large" slot="icon-only">
            <ion-icon name="move-outline"></ion-icon>
        </ion-button>
    </div>
</ng-container>