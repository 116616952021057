<ion-item *ngIf="item" [ngClass]="{ 'collection-item': isCollectionItem(item) }" (click)="select(item)" button
    class="ion-margin-vertical" detail detailIcon="chevron-forward-outline" lines="none">
    <ion-label>
        <span *ngIf="showStar(item)"> * </span>
        <jobs-placements-list-item-status [item]="item"></jobs-placements-list-item-status>
        <h2 *ngIf="item | jobListItemTitle" [ngClass]="{ bold: isHighlightedItem(item) }">
            {{ item | jobListItemTitle }}
        </h2>
        <h2 *ngIf="item | jobListItemSubTitle" [ngClass]="{ bold: isHighlightedItem(item) }">
            {{ item | jobListItemSubTitle }}
        </h2>
        <h2 *ngIf="item | jobListItemClient" [ngClass]="{ bold: isHighlightedItem(item) }">
            {{ item | jobListItemClient }}
        </h2>
        <ion-text color="primary">
            <h3 [innerHtml]="item | shiftTimeDescription"></h3>
        </ion-text>
        <ion-text *ngIf="showPayRate(item)" color="primary">
            <h3 [innerHtml]="item.jobData | payRate"></h3>
        </ion-text>
    </ion-label>
</ion-item>